.longHeading {
    display: block !important;
}
.shortHeading {
    display: none !important;
}
@media only screen and (max-width: 768px) {
    .longHeading {
        display: none !important;
    }
    .shortHeading {
        display: block !important;
    }
}